import { http, authHeader } from '@/http-commons';

class VentaEvidenceFileService {

    uploadEvidence(evidence, ventaId) {
        const formData = new FormData();
        formData.append('evidence', evidence);
        return http.post(`/api/v1/ventaEvidenceFile/uploadEvidence/${ventaId}`, formData, { headers: authHeader() });
    }

    table( params, ventaId ) {
        return http.get(`api/v1/ventaEvidenceFile/table/${ventaId}`, { params, headers : authHeader() });
    }

}

export default new VentaEvidenceFileService()