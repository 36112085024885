<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" persistent transition="scale-transition" max-width="1400px">
            <v-card>
                <v-toolbar dark text="white" color="primary">
                    <v-btn icon dark @click="handleModal">
                        <v-icon>fas fa-times-circle</v-icon>
                    </v-btn>

                    <v-toolbar-title>
                        <h4>DETALLE DE PAGO: {{ pago.folio }}</h4>
                    </v-toolbar-title>
                </v-toolbar>

                <v-card-text>
                    <v-row class="mt-2">
                        <v-col cols="12" sm="4" md="4">
                            <v-text-field :value="pago.folio" label="Folio de pago" readonly text
                                class="d-flex flex-row-reverse">
                            </v-text-field>
                        </v-col>

                        <v-col cols="12" sm="4" md="4">
                            <v-text-field :value="pago.provider.razonSocial" label="Proveedor" readonly text
                                class="d-flex flex-row-reverse">
                            </v-text-field>
                        </v-col>

                        <v-col cols="12" sm="4" md="4">
                            <v-text-field :value="pago.paymentDateNatural" label="Fecha de pago" readonly text
                                class="d-flex flex-row-reverse">
                            </v-text-field>
                        </v-col>

                        <v-col cols="12" sm="4" md="4">
                            <v-text-field :value="pago.payedAmountNatural" label="Monto pagado" readonly text
                                class="d-flex flex-row-reverse">
                            </v-text-field>
                        </v-col>

                        <v-col cols="12" sm="4" md="4">
                            <v-text-field :value="pago.paymentMethodNatural" label="Metodo de pago" readonly text
                                class="d-flex flex-row-reverse">
                            </v-text-field>
                        </v-col>

                        <v-col cols="12" sm="4" md="4">
                            <v-text-field :value="pago.operationNumber" label="Numero de operación" readonly text
                                class="d-flex flex-row-reverse">
                            </v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="12">
                            <DetailTablePaymentProvider
                            :payments="payments"
                            :proveedor-pago-id="pago.id"
                            :pago.sync="pago"/>
                        </v-col>
                    </v-row>
                </v-card-text>

                <v-card-actions>
                    <v-list-item class="grow">
                        <v-row justify="start" align="center">
                            <v-btn color="primary" dark @click="handleModal">
                                <v-icon dark left>fas fa-xmark</v-icon>Cerrar detalle
                            </v-btn>
                        </v-row>
                    </v-list-item>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
    import ProveedorPagoDetalleDataService from '@/service/catalogo/proveedor/pago/proveedorPagoDetalleDataService';
    import { getFormatCurrency } from '@/utils/CurrencyUtil';
    import DetailTablePaymentProvider from './DetailTablePaymentProvider.vue';

    export default {
        name: "DetailPayment",
        props: {
            dialog: { default: false },
            handleModal: { type: Function },
            pago: { type: Object }
        },
        created() {
            this.getPayments(this.pago.id);
        },
        components: { DetailTablePaymentProvider },
        data() {
            return {
                payments: [],
            }
        },
        methods: {
            getPayments(pagoId) {
                ProveedorPagoDetalleDataService.findByProveedorPago(pagoId).then( result => {
                    this.payments = result.data;
                }).catch( err => {
                    this.$swal.fire({ icon: 'error', title: 'Error al obtener el historial de pagos', html: err.response.data, showConfirmButton: false, timer: 1500 });
                })
            }
        },
        computed: {
            totalNatural() {
                const total = this.payments.reduce((acc, item) => acc + item.payedAmount, 0);
                return getFormatCurrency(total, ' ');
            }
        }
    }
</script>
