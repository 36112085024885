import { http, authHeader } from '@/http-commons';

class ProveedorEvidenceFileService {

    uploadEvidence(evidence, providerPaymentId) {
        const formData = new FormData();
        formData.append('evidence', evidence);
        return http.post(`/api/v1/providerEvidenceFile/uploadEvidence/${providerPaymentId}`, formData, { headers: authHeader() });
    }

    table( params, providerPaymentId ) {
        return http.get(`api/v1/providerEvidenceFile/table/${providerPaymentId}`, { params, headers : authHeader() });
    }

}

export default new ProveedorEvidenceFileService()