<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <v-card class="elevation-1">
      <v-toolbar dark color="primary">
        <v-btn icon dark @click.native="handleModal">
          <v-icon>fas fa-times-circle</v-icon>
        </v-btn>
        <v-toolbar-title>Generar reporte de venta en excel</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-card-text>
        <v-row>
          <v-col cols="6">
            <SingleCalendar refElement="startDate" title="Fecha inicio" :input-date.sync="startDate" />
          </v-col>
          <v-col cols="6">
            <SingleCalendar refElement="endDate" title="Fecha fin" :input-date.sync="endDate" :min-date="startDate" />
          </v-col>
          <v-col cols="12">
            <v-autocomplete :items="tipoSalida" item-text="nombre" item-value="id"
            prepend-inner-icon="fas fa-outdent" hint="Selecciona el tipo de salida."
            v-model="tipoSalidaId" label="Tipo salida" required outlined multiple >
            </v-autocomplete>
          </v-col>
          <v-col cols="12">
            <v-autocomplete :items="sucursales" item-text="nombre" item-value="id" v-model="sucursalId"
            label="Sucursales" required outlined prepend-inner-icon="fas fa-id-card-alt"
            :readonly="!isAdmin" hint="Seleccione la sucursal. Este campo es opcional">
            </v-autocomplete>
          </v-col>
          <v-col cols="12" class="mt-n12">
            <v-checkbox v-model="showDetail" label="Reporte detallado" color="success" hide-details></v-checkbox>
          </v-col>
          <v-col cols="12" v-if="showDetail" class="mt-n5">
            <ValidationProvider rules="required" v-slot="{ errors, valid}" :immediate="true">
              <v-autocomplete :items="products" item-text="nombre" item-value="id" v-model="productId"
              prepend-inner-icon="fas fa-boxes" hint="Seleccione el producto. Este campo es obligatorio si el reporte es detallado."
              label="Producto" required outline :error-messages="errors" :success="valid">
              </v-autocomplete>
            </ValidationProvider>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-4" dark @click.native="downloadExcelVentaByDay">
          <v-icon left>fas fa-file-excel</v-icon>Descargar excel
        </v-btn>
        <v-btn color="blue darken-4" dark class="btn text-white" @click="previewPDF">
          <v-icon left>fas fa-file-pdf</v-icon>Ver PDF
        </v-btn>
      </v-card-actions>
    </v-card>

    <PreviewPDF :pdfSource="pdfSource" :pdfBlob="pdfBlob"
      title-pdf="VENTAS" :handlePdfModal="handlePdfModal"
      :dialog="showPdf" v-if="showPdf">
    </PreviewPDF>
  </v-dialog>
</template>
<script>
import PreviewPDF from '@/components/shared/PreviewPDF.vue';
import SingleCalendar from '@/components/shared/SingleCalendar.vue';
import SucursalDataService from '@/service/catalogo/sucursal/sucursalDataService';
import ProductoDataService from '@/service/producto/ProductoDataService';
import VentaDataService from '@/service/venta/VentaDataService';
import { getDateLocaleNatural, getToday } from '@/utils/DateUtil';

export default {
  name: 'ExcelExportVenta',
  props: {
    dialog: { type: Boolean, default: false },
    handleModal: { type: Function },
    tipoSalida: { type: Array },
    sucursales: { type: Array },
    isAdmin: { type: Boolean, default: false }
  },
  components: { SingleCalendar, PreviewPDF },
  created() {
    this.getProducts();
  },
  data() {
    return {
      startDate: getToday().toFormat('yyyy-MM-dd'),
      endDate: getToday().toFormat('yyyy-MM-dd'),
      tipoSalidaId: [],
      sucursalId: SucursalDataService.getCurrentSucursalId(),
      productId: null,
      showDetail: false,
      products: [],
      // to PDF
      pdfBlob: '',
      pdfSource: null,
      showPdf: false,
    }
  },
  methods: {
    async getProducts() {
      this.products = await ProductoDataService.findAll().then( resp => resp.data );
    },
    downloadExcelVentaByDay() {
      const startDate = getDateLocaleNatural(this.startDate);
      const endDate = getDateLocaleNatural(this.endDate);
      this.$swal.fire({
        title: 'Quieres continuar con la descarga del reporte de ventas?',
        text: 'El tiempo de descarga puede variar dependiendo de la cantidad de registros',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si',
        cancelButtonText: 'No',
        showLoaderOnConfirm: true,
        preConfirm: async () => {
          const params = { startDate, endDate, sucursalId: this.sucursalId, tipoSalidaId: this.extractTypeOutId,
            productId: this.productId, typeOutNameSelected: this.extractTypeOutName
          };
          try {
            // return await (this.showDetail ? VentaDetalleDataService.downloadVentaDetailExcel(params) : VentaDataService.downloadVentaExcelByDay(params));
            return await VentaDataService.downloadVentaExcelByDay(params);
          } catch (error) {
            this.$swal.showValidationMessage(`Petición fallida: ${error}`);
          }
        },
        allowOutsideClick: () => !this.$swal.isLoading()
      }).then( result => {
        if (result.isConfirmed) {
          if( result.value.status == 204 ) {
            this.$swal.fire({ icon: 'info', title: 'Reporte', text: 'No hay registros para descargar', showConfirmButton: false, timer: 1500 });
          } else {
            const url = window.URL.createObjectURL(new Blob([result.value.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `Ventas ${startDate} - ${endDate}.xlsx`);
            document.body.appendChild(link);
            link.click();
            link.remove();
            this.$swal.fire({ icon: 'success', title: 'Reporte', text: 'Reporte descargado', showConfirmButton: false, timer: 1500 });
          }
        }
      }).catch(err => {
        this.$swal.fire({ position: 'top-end', icon: 'error', html: 'Error al obtener los datos' + err, showConfirmButton: false, timer: 3500 });
      })
    },
    previewPDF() {
      const startDate = getDateLocaleNatural(this.startDate) ?? '';
      const endDate = getDateLocaleNatural(this.endDate) ?? '';
      const params = { startDate, endDate, sucursalId: this.sucursalId, tipoSalidaId: this.extractTypeOutId,
        productId: this.productId, typeOutNameSelected: this.extractTypeOutName
      };
      VentaDataService.printSell(params).then( response => {
        if(response.status == 204) {
          this.$swal.fire({ icon: 'info', title: 'Reporte PDF ventas', text: 'No hay registros para visualizar', showConfirmButton: false, timer: 2500 });
          return
        }
        this.pdfBlob = new Blob([response.data], { type: 'application/pdf' })
        this.pdfSource = URL.createObjectURL(this.pdfBlob)
        this.showPdf = true
      }).catch( error => {
        this.$swal.fire({ position: 'top-end', icon: 'error', html: 'Error al generar el reporte' + error.response.data, showConfirmButton: false, timer: 3500 });
      });
    },
    handlePdfModal(){
      this.showPdf = !this.showPdf
      this.cleanupPdf()
    },
    cleanupPdf(){
      if(this.pdfSource){
        URL.revokeObjectURL(this.pdfSource)
        this.pdfSource = null
      }
      this.pdfBlob = null
    }
  },
  watch: {
    showDetail: function(val) {
      if(val == false) {
        this.productId = null
      }
    }
  },
  computed: {
    hasTypeOutSelected() {
      return this.tipoSalidaId.length > 0
    },
    extractTypeOutName() {
      return this.hasTypeOutSelected ? this.tipoSalida.filter(ts => this.tipoSalidaId.includes(ts.id)).map( item => item.nombre ).join(', ') : 'General'
    },
    extractTypeOutId() {
      return this.hasTypeOutSelected ? this.tipoSalidaId.join(',') : ''
    }
  }
}
</script>