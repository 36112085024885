<template>
    <v-row>
        <v-col cols="12" md="12" lg="12" xl="12">
            <v-btn tile small color="primary" @click.prevent="addCartProduct()" title="Nuevo producto (F2)">
                <v-icon small left>fas fa-plus-square</v-icon> Añadir producto (F2)
            </v-btn>
            <v-row>
                <v-col cols="12" v-if="modeInternalSell">
                    <cite class="caption mt-2 d-block text-justify grey--text">**Si es una venta interna, la unidad de salida (venta) es por kg, por lo tanto la operación sera kg por precio**</cite>
                </v-col>
                <v-col cols="12" v-else>
                    <cite class="caption mt-2 d-block text-justify grey--text">**Si la unidad de venta es en PZA, la operación sera cantidad por precio y si es en KG, la operación sera kg por precio**</cite>
                </v-col>
            </v-row>
            <v-simple-table fixed-header height="auto" dense class="elevation-1 mt-2">
                <template v-slot:default>
                    <thead class="primary">
                        <tr>
                            <th class="text-left font-weight-black">Producto</th>
                            <th class="text-left font-weight-black" v-if="modeProcess">Nuevo Producto</th>
                            <th class="text-center font-weight-black">Cantidad (PZA)</th>
                            <th class="text-center font-weight-black" v-if="modeProcess">Cantidad (KG aprox)</th>
                            <th class="text-center font-weight-black" v-else>Cantidad (KG)</th>
                            <th class="text-left font-weight-black" v-if="modeProcess">Unidad salida</th>
                            <th class="text-left font-weight-black" v-else>Unidad venta</th>
                            <th class="text-left font-weight-black">Precio</th>
                            <th class="text-left font-weight-black">Importe</th>
                            <th class="text-center font-weight-black">Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in productCart" :key="item.id">
                            <!-- solo, solo-inverted, solid -->
                            <td>
                                <ValidationProvider rules="required" v-slot="{ errors, valid}" :immediate="true">
                                    <v-autocomplete class="mt-2" :items="products" item-text="nombre" item-value="id"
                                    filled outlined required solid
                                    @change="(e) => changeProduct(e, index)"
                                    :value="item.productoId" :error-messages="errors" :success="valid">
                                    </v-autocomplete>
                                </ValidationProvider>
                            </td>
                            <td v-if="modeProcess">
                                <ValidationProvider rules="required" v-slot="{ errors, valid}" :immediate="true">
                                    <v-autocomplete class="mt-2" :items="productsProcess" item-text="nombre" item-value="id"
                                    filled outlined required solid
                                    @change="(e) => changeNewProduct(e, index)"
                                    :value="item.newProductId" :error-messages="errors" :success="valid">
                                    </v-autocomplete>
                                </ValidationProvider>
                            </td>
                            <td>
                                <ValidationProvider 
                                :rules="item.isServicio ? 'required|numeric|min_value:1' : `required|numeric|min_value:1|max_value:${item.stock}`"
                                v-slot="{ errors, valid }" :immediate="true">
                                    <v-text-field v-model="item.cantidad" type="number" class="mt-2 font-weight-black text-h6"
                                        solo dark persistent-hint required min="1" :max="item.stock" placeholder="0.00"
                                        @input="(e) => changeItem(e, index, 'cantidad')"
                                        :error-messages="errors" :success="valid">
                                    </v-text-field>
                                </ValidationProvider>
                            </td>
                            <td>
                                <ValidationProvider :rules="item.isServicio ? '' : 'required|decimal|min_value:0.5'"
                                v-slot="{ errors, valid }" :immediate="true">
                                    <v-text-field v-model="item.kg" type="number" placeholder="0.00" class="mt-2 font-weight-black text-h6"
                                        solo dark persistent-hint @input="(e) => changeItem(e, index, 'kg')"
                                        required min="0.5" :error-messages="errors" :success="valid"
                                        :disabled="item.isServicio || modeProcess">
                                    </v-text-field>
                                </ValidationProvider>
                            </td>
                            <td>
                                <v-text-field :value="item.nameUnit" type="text" solo dense prepend-inner-icon="fas fa-list"
                                class="font-weight-bold text-h6 mt-2 green--text" persistent-hint disabled>
                                </v-text-field>
                            </td>
                            <td>
                                <v-text-field :value="item.precioVenta" type="number" solo dense
                                class="font-weight-medium text-h6 mt-2 green--text" persistent-hint prefix="$" disabled>
                                </v-text-field>
                            </td>
                            <td>
                                <v-text-field :value="item.total" type="number" solo dense
                                class="font-weight-medium text-h6 mt-2 green--text" persistent-hint prefix="$" disabled>
                                </v-text-field>
                            </td>
                            <td>
                                <v-row align="center" justify="center" class="mb-1">
                                    <span class="group pa-1">
                                        <v-icon medium class="mx-1 pa-1" left color="red darken-4" @click="deleteProduct(item, index)">fas fa-trash-alt</v-icon>
                                    </span>
                                </v-row>
                            </td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>
            <v-row>
                <v-col cols="12" sm="12" xs="12" md="12">
                    <v-chip class="ma-2 mountSell" large label text-color="white"
                    :color="modeMerma ? 'red darken-3' : 'green darken-4'">
                        <v-icon large left>fas fa-dollar-sign</v-icon>
                        <p class="text-uppercase mt-4 pa-2 white--text font-weight-bold text-h4">
                            {{ importeNatural }}
                        </p>
                    </v-chip>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<script>
import SucursalDataService from '@/service/catalogo/sucursal/sucursalDataService';
import EntradaDetalleDataService from '@/service/entrada/EntradaDetalleDataService';
import ProductoDataService from '@/service/producto/ProductoDataService';

export default {
    name: "addSellProduct",
    props: {
        venta: { type: Object, default: () => ({}) },
        products: {},
        listEntradaDetalle: { type: Array, default: () => [] },
    },
    mounted() {
        document.addEventListener('keydown', this.addListenerProduct);
    },
    destroyed() {
        document.removeEventListener('keydown', this.addListenerProduct);
    },
    data() {
        return {
            productCart: [],
            importeNatural: 0,
            detailProduct: [],
            productSelectedId: 0,
            productsProcess: [],
        }
    },
    methods: {
        generateCartProduct() {
            return {
                id: null,
                ventaId: null,
                productoListaPrecioId: null,
                productoId: null,
                newProductId: null,
                producto: '',
                unidad: '',
                // modeInternalSell
                unidadSalidaId: null,
                cantidad: 0,
                kg: 0,
                stock: 0,
                precioVenta: 0,
                total: 0,
                isServicio: false,
            }
        },
        addCartProduct() {
            if(this.productCart.length > 0){
                const hasProductEmpty = this.productCart.find(x => x.productoId == null );
                if(hasProductEmpty){
                    this.$swal.fire({ position : 'top-end', icon: 'info', text: 'Ya existe un producto vacio en la lista', showConfirmButton: false, timer: 2000 });
                    return;
                }
            }
            this.productCart.push(this.generateCartProduct());
        },
        addListenerProduct(e) {
            if (e.keyCode === 174) {
                this.addCartProduct();
            }
        },
        deleteProduct(cartElement, index){
            this.enableProduct(cartElement.productoId, cartElement);
            this.productCart.splice(index, 1);
        },
        enableProduct(productSelected, cartElement){
            const indexProduct = this.products.findIndex(x => x.id == productSelected);
            const indexPrevProduct = this.products.findIndex(x => x.id == cartElement.productoId);
            if(indexProduct > -1){
                this.products[indexProduct].disabled = true;
            }
            if(indexPrevProduct > -1) {
                this.products[indexPrevProduct].disabled = false;
            }
        },
        reEnableAllProducts() {
            this.products.forEach(product => product.disabled = false);
        },
        changeProduct(e, index){
            if( e != null && e > 0 ) {
                const prevProduct = this.productCart[index];
                this.enableProduct(e, prevProduct);
                this.productCart[index].productoId = e;
                this.productSelectedId = e;
                const product = this.products.find(prod => prod.id == e);

                this.productCart[index].cantidad = 1;
                this.productCart[index].kg = 0;
                this.productCart[index].precioVenta = product.precioVenta || 0;
                this.productCart[index].productoListaPrecioId = product.listaPrecioId;
                this.productCart[index].producto = product.nombre;
                this.productCart[index].unidad = product.tipoUnidad.clave;
                this.productCart[index].nameUnit = product.tipoUnidad.nombre;
                // Start: It was added only to the case modeInternalSell
                this.productCart[index].unidadSalidaId = product.tipoUnidad.id;
                this.productCart[index].unitOutName = product.tipoUnidad.nombre;
                // End
                this.productCart[index].isServicio = product.isServicio;
                this.productCart[index].total = this.getTotal(this.productCart[index]);
            }
        },
        changeNewProduct(e, index){
            if( e != null && e > 0 ) {
                const prevProduct = this.productCart[index].productoId;
                this.productCart[index].newProductId = e;
                this.productCart[index].kg = this.detailProduct.filter(prod => prod.productoId == prevProduct)[0].averageWeight || 0;
            }
        },
        async getDetailsProduct( productId ){
            const sucursalId = SucursalDataService.getCurrentSucursalId();
            await EntradaDetalleDataService.getEntradaDetalleProductoSucursal( productId, sucursalId).then( resp => {
                this.detailProduct = resp.data || [];
                this.$emit('getDetailProduct', this.detailProduct);
                this.validateStock( productId );
            });
        },
        getTotal(oneProductCart){
            const unity = oneProductCart.unidad;
            const precio = oneProductCart.precioVenta;
            if(this.modeInternalSell){
                return oneProductCart.kg * precio;
            }
            return unity == 'KG' ? (oneProductCart.kg * precio) : (oneProductCart.cantidad * precio);
        },
        changeItem(e, index, field){
            if(e != null && e > 0) {
                this.productCart[index][field] = e;
                if(this.productCart[index].newProductId != null && this.productCart[index].newProductId > 0){
                    const averageWeight = this.detailProduct.filter( x => x.productoId == this.productCart[index].productoId )[0].averageWeight || 0;
                    this.productCart[index].kg = averageWeight * this.productCart[index].cantidad;
                }
                if(this.modeInternalSell){
                    this.productCart[index].unidadSalidaId = 2;
                    this.productCart[index].unitOutName = 'KG';
                }
                this.productCart[index].total = this.getTotal(this.productCart[index]);
            }
        },
        validateStock( productSelectedId ){
            const indexProduct = this.products.findIndex(x => x.id == productSelectedId );
            const indexProductCart = this.productCart.findIndex(x => x.productoId == productSelectedId );
            const itemProduct = this.products.find(x => x.id == productSelectedId );
            if(this.productCart[indexProductCart].isServicio){
                return;
            }
            if( this.detailProduct == undefined || this.detailProduct.length == 0 ){
                this.$swal.fire({ position: 'top-right', icon: 'warning', title: 'Existencias',
                html: `<strong>${itemProduct.nombre.toUpperCase() }</strong> sin existencia en la sucursal`, showConfirmButton: false, timer: 3500 });
                this.products[indexProduct].disabled = false;
                this.productCart[indexProductCart].productoId = null;
                this.productCart[indexProductCart].stock = 0;
            } else {
                const productStock = this.detailProduct.filter( x => x.productoId == productSelectedId )
                .reduce( (total, item) => total + item.existencia, 0 );
                this.productCart[indexProductCart].stock = productStock;
                if( productStock <= 0 ){
                    this.products[indexProduct].disabled = false;
                }
            }

        },
        emitAddCartProduct(data){
            const total = data.reduce( (acm, item) => acm + item.total, 0 );
            this.importeNatural = total.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
            this.$emit('addProductCart', data);
        },
        async getAllProduct(){ this.productsProcess = await ProductoDataService.findAll().then( resp => resp.data ) || [] },
    },
    watch: {
        productCart: {
            handler(newValue) {
                this.emitAddCartProduct(newValue);
            },
            deep: true
        },
        productSelectedId(newValue){
            if( newValue != null && newValue > 0 ) {
                this.reEnableAllProducts();
                if( this.modeSell ){
                    this.getDetailsProduct( newValue );
                    this.productSelectedId = 0;
                } else {
                    const lstDetailProduct = this.listEntradaDetalle.find( entry => entry.id == this.venta.entradaDetalleId );
                    this.detailProduct = lstDetailProduct ? [lstDetailProduct] : [];
                    this.$emit('getDetailProduct', this.detailProduct);
                    this.validateStock( newValue );
                    this.productSelectedId = 0;
                }
            }
        },
        'venta.entradaDetalleId': function( newValue ){
            if( newValue != null && newValue > 0 && this.productCart.length > 0 ) {
                const lstDetailProduct = this.listEntradaDetalle.find( entry => entry.id == newValue );
                this.detailProduct = lstDetailProduct ? [lstDetailProduct] : [];
                this.$emit('getDetailProduct', this.detailProduct);
                this.validateStock( newValue );
                this.productSelectedId = 0;
            }
        },
        'venta.tipoSalidaId': function( newValue ){
            this.productSelectedId = 0;
            if(newValue != null && newValue > 0 && newValue == 4 && this.productsProcess.length == 0){
                this.getAllProduct();
            }
        }
    },
    computed: {
        modeSell() { return this.venta.tipoSalidaId == 1 || this.venta.tipoSalidaId == 5; },
        modeMovement() { return this.venta.tipoSalidaId == 2 || this.venta.tipoSalidaId == 3; },
        modeMerma() { return this.venta.tipoSalidaId == 2; },
        modeProcess() { return this.venta.tipoSalidaId == 4; },
        modeInternalSell() { return this.venta.tipoSalidaId == 5; }
    }
}
</script>
<style>
    .mountSell {
        width: 100%;
        justify-content: center;
    }
</style>