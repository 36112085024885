import { http, authHeader } from "@/http-commons";

class ProveedorPagoDataService {

    store(proveedorPago){
        return http.post("/api/v1/proveedorPago/store", proveedorPago, authHeader());
    }
    table(params){
        return http.get("/api/v1/proveedorPago/table", {params, headers: authHeader()});
    }
    findByVentaPago(proveedorPagoId){
        return http.get(`/api/v1/proveedorPago/findByProveedorPago/${proveedorPagoId}`, {headers: authHeader()});
    }
    downloadExcelPago(params){
        return http.get("/api/v1/proveedorPago/downloadPagos", {params, headers: authHeader(), responseType: 'blob' });
    }
    printPaymentProvider( paymentProviderId ){
        return http.get(`/api/v1/proveedorPago/printPaymentProvider/${paymentProviderId}`, {headers: authHeader(), responseType: 'blob'});
    }

}

export default new ProveedorPagoDataService();