<template>
    <div>
        <v-chip class="calendario font-weight-medium text-center" color="pink darken-2" label text-color="white">
            <v-icon left class="ml-2">fas fa-credit-card</v-icon>
            <p class="text-uppercase mt-4 white--text font-weight-bold text-h7">
                Credito: {{ creditClient?.nombreComercial || '' }}
            </p>
        </v-chip>
        <v-simple-table fixed-header height="auto" dense class="mt-1">
            <thead>
                <th></th>
                <th></th>
            </thead>
            <tbody class="bg-secondary">
                <tr>
                    <td class="font-weight-black">Dias de credito</td>
                    <td class="font-weight-bold">{{ creditClient.diasCredito }}</td>
                </tr>
                <tr>
                    <td class="font-weight-black">Documento permitido</td>
                    <td class="font-weight-bold">{{ creditClient.documentoCredito }}</td>
                </tr>
                <tr>
                    <td class="font-weight-black">Limite credito</td>
                    <td class="font-weight-bold">{{ creditClient.limiteCreditoNatural }}</td>
                </tr>
                <tr>
                    <td class="font-weight-black red--text">Documento pendiente</td>
                    <td class="font-weight-black red--text">{{ creditClient.numberDocument }}</td>
                </tr>
                <tr>
                    <td class="font-weight-black red--text">Venta pendientes</td>
                    <td class="font-weight-black red--text">{{ creditClient.debtNatural }}</td>
                </tr>
                <tr>
                    <td class="font-weight-black green--text">Saldo disponible</td>
                    <td class="font-weight-black green--text">{{ creditClient.balanceNatural }}</td>
                </tr>
            </tbody>
        </v-simple-table>
    </div>
    <!-- <v-expansion-panels inset focusable class="rounded">
        <v-expansion-panel>
            <v-expansion-panel-header>Detalle de credito: {{ creditClient.nombreComercial }}</v-expansion-panel-header>
            <v-expansion-panel-content>
            </v-expansion-panel-content>
        </v-expansion-panel>
    </v-expansion-panels> -->
</template>
<script>
import clienteDataService from '@/service/cliente/clienteDataService';

export default {
    name: 'DetailCreditClient',
    props: {
        clientId: { type: Number, required: true }
    },
    data() {
        return {
            creditClient: {},
            allowToSell: false
        }
    },
    methods: {
        getCreditClient() {
            if (this.clientId) {
                clienteDataService.getDetailCredit(this.clientId).then( result => {
                    this.creditClient = result.data
                    this.$emit('creditClient', this.creditClient)

                })
            }
        }
    },
    watch: {
        clientId: {
            immediate: true,
            handler() {
                this.getCreditClient();
            }
        }
    }
}
</script>